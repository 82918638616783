import InputOrFlatpickr from 'components/InputOrFlatpickr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Modal, ModalBody } from 'reactstrap';
import { changeShiftField } from 'redux/ducks/Post/Shifts/actions';
import { isEmpty } from 'lodash';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { shiftDuration, DATE_PICKER_OPTIONS, TIME_PICKER_OPTIONS } from 'helpers/post';
import classNames from 'classnames';
import { hour24ToMeridiem, meridiemTo24 } from 'helpers/datetime';
import ToolTip from 'scenes/V2/ToolTip';

const INITIAL_VALIDATION_STATE = {
  start: false,
  end: false,
  capacity: false,
  startDate: false,
  endDate: false,
  payRate: false,
  rateTypeId: false,
};

class AddShiftModal extends Component {
  state = { wasValidated: false, validated: INITIAL_VALIDATION_STATE };
  validateField = (field) =>
    this.setState({ ...this.state, validated: { ...this.state.validated, [field]: true } });

  onChangeField = (name, value) => this.props.onChangeField(this.props.shift.idx)(name, value);
  showError = (field) =>
    (this.state.wasValidated || this.state.validated[field]) &&
    !!this.props.shift.errorMessages[field];
  onFieldBlur = (field) => () => this.validateField(field);
  onCapacity = (amount) => this.onChangeField('capacity', amount);
  toggleIsMultiDay = () => {
    const { shift } = this.props;
    const downFront = shift.isMultiDay;
    this.onChangeField('isMultiDay', !shift.isMultiDay);
    if (downFront) this.onChangeField('startDate', shift.startDate);
    if (!downFront) this.onChangeField('rateTypeId', '1');
  };
  onFlatpickr = (name) => (value) => this.onChangeField(name, value);
  onTimeFlatpickr = (name) => (value) => this.onChangeField(name, meridiemTo24(value));
  onChange = (name) => (e) => this.onChangeField(name, e.target.value);

  addStaff = (amount) => () => {
    const newCapacity = Number(this.props.shift.capacity) + amount;
    this.onCapacity(newCapacity < 1 ? 1 : newCapacity);
  };

  formIsValid = () => {
    return Object.values(this.props.shift.errorMessages).every(isEmpty);
  };

  updateShift = () => {
    if (!this.formIsValid()) {
      this.setState({ ...this.state, wasValidated: true });
      return;
    }

    this.setState({ ...this.state, validated: INITIAL_VALIDATION_STATE, wasValidated: false });
    this.props.toggle();
  };

  renderStartDate = () => {
    const { shift } = this.props;
    return (
      <div
        className={classNames('form-group', {
          'col-md-6': shift.isMultiDay,
          'col-md-7': !shift.isMultiDay,
        })}
      >
        <label htmlFor="startDate">{shift.isMultiDay ? 'Start Date' : 'Shift Date'}</label>
        <div className="input-group">
          <InputOrFlatpickr
            type="datetime"
            name="startDate"
            value={shift.startDate}
            options={DATE_PICKER_OPTIONS}
            onChange={this.onFlatpickr('startDate')}
            onBlur={this.onFieldBlur('startDate')}
            className={classNames('form-control lbr', {
              'is-invalid': this.showError('startDate'),
            })}
            placeholder={moment().format('MM-DD-YYYY')}
          />
          <div className="input-group-append">
            <span
              className="input-group-text rbr cursor-pointer"
              onClick={() => {
                const input = document.querySelector(`input[name="startDate"]`);
                if (input) input.focus();
              }}
            >
              <i className="far fa-calendar-alt"></i>
            </span>
          </div>
          <div className="invalid-feedback">{shift.errorMessages['startDate']}</div>
        </div>
      </div>
    );
  };

  renderEndDate = () => {
    const { shift } = this.props;
    if (!shift.isMultiDay) return null;

    return (
      <div className="form-group col-md-6">
        <label htmlFor="endDate">End Date</label>
        <div className="input-group">
          <InputOrFlatpickr
            type="datetime"
            name="endDate"
            value={shift.endDate}
            options={DATE_PICKER_OPTIONS}
            onChange={this.onFlatpickr('endDate')}
            onBlur={this.onFieldBlur('endDate')}
            className={classNames('form-control lbr', {
              'is-invalid': this.showError('endDate'),
            })}
            placeholder={moment().format('MM-DD-YYYY')}
          />
          <div className="input-group-append">
            <span
              className="input-group-text rbr cursor-pointer"
              onClick={() => {
                const input = document.querySelector(`input[name="endDate"]`);
                if (input) input.focus();
              }}
            >
              <i className="far fa-calendar-alt"></i>
            </span>
          </div>
          <div className="invalid-feedback">{shift.errorMessages['endDate']}</div>
        </div>
      </div>
    );
  };

  renderMultiDayToggler = () => {
    const { shift, shiftCount } = this.props;
    if (shiftCount > 1) return null;

    return (
      <div className="row multi-day">
        <div className={classNames(`form-group col-md-12`)}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={shift.isMultiDay}
              onChange={this.toggleIsMultiDay}
              id={`multi-day-shift-${shift.idx}`}
            />
            <label className="custom-control-label" htmlFor={`multi-day-shift-${shift.idx}`}>
              Combine Multiple Days into One Shift
              <ToolTip
                id="multi-day-shift-icon"
                top="3px"
                bodyText="Select this option to combine several days into a single shift, requiring ATs to work all included days. Note that this option only allows for a fixed pay rate across all days and does not allow hourly rates."
              />
            </label>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isOpen, toggle, timezone } = this.props;
    const { shift } = this.props;

    if (!shift) return null;

    return (
      <div className="was-validated">
        <Modal
          keyboard={false}
          isOpen={isOpen}
          toggle={toggle}
          className="add-shift-modal"
          backdrop="static"
        >
          <ModalBody style={{ backgroundColor: 'white' }}>
            <div className="modalTitle">
              <div className="screen-title">Shift {shift.idx + 1}</div>
              <span className="text-secondary hrs">
                <span className="text-secondary hrs">{shiftDuration(shift)}h</span>
              </span>
            </div>
            <div className="inputs">
              <div id="shiftModalForm">
                {this.renderMultiDayToggler()}
                <div className="row">
                  {this.renderStartDate()}
                  {this.renderEndDate()}
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="start">Start Time</label>
                        <div className="input-group">
                          <InputOrFlatpickr
                            name="start"
                            value={hour24ToMeridiem(shift.start)}
                            options={TIME_PICKER_OPTIONS}
                            type="datetime"
                            onValueUpdate={this.onTimeFlatpickr('start')}
                            onBlur={this.onFieldBlur('start')}
                            className={classNames('form-control fbr', {
                              'is-invalid': this.showError('start'),
                            })}
                            placeholder="12:00 PM"
                            timezone={timezone}
                          />
                          <div className="invalid-feedback">{shift.errorMessages['start']}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="end">End Time</label>
                        <div className="input-group">
                          <InputOrFlatpickr
                            name="end"
                            value={hour24ToMeridiem(shift.end)}
                            options={TIME_PICKER_OPTIONS}
                            type="datetime"
                            onValueUpdate={this.onTimeFlatpickr('end')}
                            onBlur={this.onFieldBlur('end')}
                            className={classNames('form-control fbr', {
                              'is-invalid': this.showError('end'),
                            })}
                            placeholder="2:00 PM"
                            timezone={timezone}
                          />
                          <div className="invalid-feedback">{shift.errorMessages['end']}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <label htmlFor="capacity">
                      How many Athletic Trainers do you need for this shift?
                    </label>
                    <div className="input-group col-md-6 p-0">
                      <div className="input-group-append">
                        <span
                          type="button"
                          onClick={this.addStaff(-1)}
                          className="input-group-text lbr plus-minus remove-border-r"
                        >
                          <i className="fas fa-minus"></i>
                        </span>
                      </div>
                      <input
                        type="number"
                        name="capacity"
                        value={shift.capacity}
                        onChange={(e) => this.onCapacity(e.target.value)}
                        onBlur={this.onFieldBlur('capacity')}
                        className={classNames('form-control text-center pl-4 gotham-bold-font', {
                          'is-invalid': this.showError('capacity'),
                        })}
                        placeholder="1"
                        min={1}
                      />
                      <div className="input-group-append">
                        <span
                          type="button"
                          onClick={this.addStaff(1)}
                          className="input-group-text rbr plus-minus"
                        >
                          <i className="fas fa-plus"></i>
                        </span>
                      </div>
                      <div className="invalid-feedback">{shift.errorMessages['capacity']}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <label htmlFor="rate_type">Rate Type</label>
                    <div className="d-flex flex-row">
                      {!shift.isMultiDay && (
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            className="custom-control-input"
                            id="HourlyRateType"
                            type="radio"
                            name="rate_type"
                            value="0"
                            checked={shift.rateTypeId === '0'}
                            onChange={this.onChange('rateTypeId')}
                          />
                          <label
                            htmlFor="HourlyRateType"
                            className="custom-control-label radio-inline font-weight-bold"
                          >
                            HOURLY RATE
                          </label>
                        </div>
                      )}
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          className="custom-control-input"
                          id="GameRateType"
                          type="radio"
                          name="rate_type"
                          value="1"
                          checked={shift.rateTypeId === '1'}
                          onChange={this.onChange('rateTypeId')}
                        />
                        <label
                          htmlFor="GameRateType"
                          className="custom-control-label radio-inline font-weight-bold"
                        >
                          FIXED RATE
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">
                    <label htmlFor="pay_rate">Rate</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <input
                        className={classNames('form-control', {
                          'is-invalid': this.showError('payRate'),
                        })}
                        type="number"
                        name="pay_rate"
                        min="1"
                        max="100000"
                        step="1"
                        aria-label="Amount (to the nearest dollar)"
                        value={shift.payRate}
                        placeholder="0"
                        onChange={this.onChange('payRate')}
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {rateTypeNameFinder(this.props.rateTypes, shift.rateTypeId)}
                        </span>
                      </div>
                      <div className="invalid-feedback">{shift.errorMessages['payRate']}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modalActions">
              <div className="actions">
                <Button
                  className={classNames('btn btn-success', {
                    disabled: !this.formIsValid(),
                  })}
                  block
                  size="sm"
                  color="success"
                  onClick={this.updateShift}
                >
                  SAVE
                </Button>
                <button
                  className="cancel-btn"
                  onClick={() => {
                    this.props.onCancel && this.props.onCancel();
                    toggle();
                  }}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rateTypes: state.enums.rateTypes,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangeField: (index: number) => (name: string, value: any) =>
    dispatch(changeShiftField({ index, name, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddShiftModal);
